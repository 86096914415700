.userContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: black;
    flex-wrap:wrap;
  }

.topBar {
display: flex;
justify-content: center;
background: #111111;
border-radius: 4px 4px 0 0;
height: 60px;
width: 100%;
color: white;
}

div .active {
  background-color: #4CAF50;

}

.flexing {
  display:flex;
  flex-direction: column;
}